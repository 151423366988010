import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import {
  BlockWithIcon,
  BlockWithIconIcon,
  BlockWithIconText,
  BlockWithIconWrap
} from "../components/elements/BlockWithIcon";
import SliderPageHeader from "../components/elements/SliderPageHeader";
import ButtonLink from "../components/elements/ButtonLink";

const ReduceCosts = () => (
  <Index>
    <Seo title="Reduce Costs" />
    <SliderPageHeader id="slide-2-inner" extraContainerClasses="content-right">
      <h1 className="font-light">
        Improve <br />
        <b>Patient Outcomes</b>
      </h1>
      <footer>
        <ButtonLink href="/request-demo/" text="Schedule a Call" type="primary" />
      </footer>
    </SliderPageHeader>
    <Container extraContainerClasses="background-very-light-gray pad-out smaller">
      <h2 className="align-center text-blue" id="benefits-at-a-glance">Benefits at-a-glance:</h2>
      <BlockWithIconWrap>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="algorithms" />
          <BlockWithIconText>
            <h2>
              Synthesizes and Organizes Data on <br />Your Patient Population
            </h2>
            <p>
              InCircle uses a proprietary algorithm, coupled with expert review and <br />
              data analysis to ensure appropriate care for each patient.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="piggy-bank" />
          <BlockWithIconText>
            <h2>Minimizes Costs for Payors</h2>
            <p>
              Removing unnecessary treatment costs means health insurers <br/>
              won’t waste spending on treatments that don’t work.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="patient-care" />
          <BlockWithIconText>
            <h2>Helps Providers Improve <br />Patient Care</h2>
            <p>
              Prescribing physicians can rely upon the expertise of a panel of industry <br />
              experts, giving them more time to do what they do best – care for patients.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="reduced-costs" />
          <BlockWithIconText>
            <h2>Reduces Specialty Pharmacy <br />Drug Costs</h2>
            <p>
              Specialty drug prescriptions grew at more than double the rate of <br />
              traditional medicines.* InCircle uses a proprietary algorithm, <br />
              coupled with expert review and data analysis to ensure appropriate <br />
              care for each patient.
            </p>
            <p>
              <em>*IQVIA National Sales Perspectives, National Prescription Audit, Jan 2019</em>
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
      </BlockWithIconWrap>
    </Container>
  </Index>
)

export default ReduceCosts
